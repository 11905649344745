<template>
  <img
    :src="mainSrc"
    :alt="alt"
    :width="width"
    :height="height"
    :title="title"
    :loading="loading"
    :decoding="decoding"
    :sizes="sizes"
    :srcset="srcset"
    :usemap="usemap"
    :ismap="ismap"
    :crossorigin="crossorigin"
    :referrerpolicy="referrerpolicy"
  />
</template>
<script setup lang="ts">
const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: '',
  },
  width: {
    type: [String, Number],
    default: '',
  },
  height: {
    type: [String, Number],
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  loading: {
    type: String as PropType<'eager' | 'lazy'>,
    default: 'eager',
  },
  decoding: {
    type: String as PropType<'sync' | 'async' | 'auto'>,
    default: 'auto',
  },
  sizes: {
    type: String,
    default: '',
  },
  srcset: {
    type: String,
    default: '',
  },
  usemap: {
    type: String,
    default: '',
  },
  ismap: {
    type: Boolean,
    default: false,
  },
  crossorigin: {
    type: String as PropType<'anonymous' | 'use-credentials'>,
    default: '',
  },
  referrerpolicy: {
    type: String as PropType<
      | ''
      | 'no-referrer'
      | 'no-referrer-when-downgrade'
      | 'origin'
      | 'origin-when-cross-origin'
      | 'same-origin'
      | 'strict-origin'
      | 'strict-origin-when-cross-origin'
      | 'unsafe-url'
    >,
    default: '',
  },
  format: {
    type: String as PropType<'avif' | 'webp' | 'jpeg'>,
    required: false,
    default: 'avif',
    validator: (value: string) => ['avif', 'webp', 'jpeg'].includes(value),
  },
  auto: {
    type: Boolean,
    required: false,
    default: false,
  },
  fit: {
    type: String as PropType<'cover' | 'contain' | 'fill' | 'inside' | 'outside'>,
    required: false,
    default: 'contain',
    validator: (value: string) => ['cover', 'contain', 'fill', 'inside', 'outside'].includes(value),
  },
  preload: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const defaultSrc = '/default.svg';

const image = useImage();
console.log(props, 'imageProps');

function generateFakeUrl() {
  const url = props.src || defaultSrc;
  const params = new URLSearchParams();
  params.append('f', 'jpeg');
  if (!props.auto) params.append('s', '488x650');
  params.append('fit', props.fit);
  params.append('url', url);
  return `/_ipx/${params.toString()}`;
}

const mainSrc = computed(() => {
  const imgUrl =
    props.src || defaultSrc
      ? image(props.src || defaultSrc, {
          ...(!props.auto && { width: Number(props.width), height: Number(props.height) }),
          fit: props.fit,
          format: props.format,
          enlarge: true,
          background: 'transparent',
        })
      : generateFakeUrl();

  if (props.preload) {
    useHead({
      link: [
        {
          rel: 'preload',
          as: 'image',
          href: imgUrl,
        },
      ],
    });
  }

  return imgUrl;
});
</script>
